import Templates from "./templates";
import Microform from "./microform";

import microformCss from '!!raw-loader!../assets/styles/ipx-microform.css';
import fontAwsomeCss from '!!raw-loader!../assets/styles/font-awsome.css';
import vehiclesTableCss from '!!raw-loader!../assets/styles/vehicles-table.css';

class IpxMicroformComponent extends HTMLElement {

    constructor() {
        super();

        this.root = this.attachShadow({ mode: "open" });

        let baseUrl = this.baseUrl !== "" ? this.baseUrl : "https://www.interplex.net";
        let apiUrl = "https://beta4.triptender.net/api";
        let googleApiKey = "AIzaSyCLBWfH51GW3eo7cwzci_RgcE9ew8HCRg4";

        const templates = new Templates(baseUrl, apiUrl);

        this.root.innerHTML = `
            <style>
            ${microformCss}
            ${fontAwsomeCss}
            ${vehiclesTableCss}
            </style>
            ${templates.content}
            ${templates.loader}`;

        let microform = new Microform(this.token, this.player, this.baseUrl, apiUrl, googleApiKey, this.fromPoi, this.toPoi, this.language);
        microform.run();
    }

    get token() {
        return this.getAttribute("token");
    }

    get player() {
        return this.getAttribute("player");
    }

    get fromPoi() {
        return this.getAttribute("fromPoi");
    }

    get toPoi() {
        return this.getAttribute("toPoi");
    }

    get language() {
        return this.getAttribute("language");
    }

    get baseUrl() {
        return this.getAttribute("baseUrl") || "";
    }

}

customElements.define("ipx-microform-component", IpxMicroformComponent);
